import { decode } from "blurhash";
import { useState } from "react";
import { Maybe } from "../utils/types";
import useIsomorphicLayoutEffect from "./useIsomorphicLayoutEffect";
import useStableValue from "./useStableValue";

const cache = new Map<string, string>();

type Options = {
  width?: number;
  height?: number;
  lazy?: boolean;
  enable?: boolean;
};

function blurhashToDataURL(
  blurhash: Maybe<string>,
  options: Options = {}
): string {
  if (typeof document === "undefined") {
    return "";
  }

  if (!blurhash) {
    return "";
  }

  if (cache.has(blurhash)) {
    return cache.get(blurhash)!;
  }

  const { width, height } = options;
  if (!width || !height) {
    return "";
  }

  const canvas = document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext("2d");
  if (ctx) {
    const img = ctx.createImageData(width, height);
    img.data.set(decode(blurhash, width, height));
    ctx.putImageData(img, 0, 0);

    const dataURL = canvas.toDataURL();

    cache.set(blurhash, dataURL);
    return dataURL;
  }
  return "";
}

const defaultOptions = { width: 32, height: 32, lazy: false, enable: true };

export default function useBlurhash(
  blurhash: Maybe<string>,
  options: Options = {}
) {
  const opts = useStableValue({
    ...defaultOptions,
    ...options
  });

  const [blurDataURL, setBlurDataURL] = useState(() =>
    opts.enable && !opts.lazy ? blurhashToDataURL(blurhash, opts) : ""
  );

  useIsomorphicLayoutEffect(() => {
    if (opts.enable) {
      setBlurDataURL(blurhashToDataURL(blurhash, opts));
    }
  }, [blurhash, opts]);

  return blurDataURL;
}
