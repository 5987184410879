import { dequal } from "dequal";
import { useEffect, useState } from "react";

export default function useStableValue<T>(value: T) {
  const [_v, set] = useState<T>(() => value);
  useEffect(() => {
    if (!dequal(value, _v)) {
      set(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  return _v;
}
