import * as React from "react";

function Question(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 6a3.5 3.5 0 00-3.5 3.5 1 1 0 102 0A1.5 1.5 0 1112 11a1 1 0 00-1 1v2a1 1 0 002 0v-1.16A3.49 3.49 0 0012 6zM12 18a1 1 0 100-2 1 1 0 000 2z"
        fill="CurrentColor"
      />
      <circle
        cx={12}
        cy={12}
        r={9.25}
        stroke="currentColor"
        strokeWidth={1.5}
      />
    </svg>
  );
}

export default Question;
