import React from "react";
import styled from "styled-components";
import { Item } from "../../../data/db/types";
import { pxToRem } from "@input-output-hk/px-to-rem";
import { trueOrUndef } from "../../../utils/dom-attribute-helpers";
import { getPrefferedImageUrl, YouTubeImage } from "../../../utils/image";
import Glossary from "../../icons/Glossary";
import LogoIcon from "../../icons/Logo";
import Question from "../../icons/Question";
import Image from "../../image/Image";
import {
  ItemPlaceholderHeading,
  ItemPlaceholderImage
} from "../ItemPlaceholderImage";
import { FAQBackground, FAQHeading } from "../FAQBackground";
import { getFaqColorFromId } from "../FAQBackground.helpers";
import { GlossaryBackground, GlossaryHeading } from "../GlossaryBackground";
import { getGlossaryVariantFromId } from "../GlossaryBackground.helpers";
import { CardAppearance } from "./ContentCard.types";
import { getBlurHash, getBlurDataURL } from "./ContentCardImage.helpers";
import { getItemLineShapeFromId } from "../ItemPlaceholderImage.helpers";

const IconFaq = styled(Question)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const IconGlossary = styled(Glossary)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const PlaceholderImage = styled.div`
  width: ${pxToRem(100)};
  height: ${pxToRem(100)};
  overflow: hidden;
  border-radius: ${pxToRem(15)};
  border: ${pxToRem(1)} solid rgba(26, 52, 185, 0.15);
`;

const PlaceholderLogo = styled(LogoIcon)`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const CardImage = styled.div.attrs<CardAppearance>((props) => ({
  "data-appearance": props.appearance || "default"
}))<CardAppearance>`
  position: relative;
  background: var(--theme-primary);
  border-radius: ${pxToRem(15)};
  overflow: hidden;

  &[data-appearance="inline"] {
    width: ${pxToRem(100)};
    height: ${pxToRem(100)};
    border: ${pxToRem(1)} solid rgba(26, 52, 185, 0.15);
  }

  &[data-appearance="default"] {
    height: ${pxToRem(215)};
  }

  &[data-appearance="large"] {
    height: ${pxToRem(300)};
  }

  &[data-video] {
    & > span::after {
      top: 0;
      left: 0;
      display: block;
      content: "";
      background: transparent
        url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGNpcmNsZSBjeD0iMjQiIGN5PSIyNCIgcj0iMjQiIGZpbGw9IiMyMzUzRkYiLz48cGF0aCBkPSJNMzMgMjIuMjY4YzEuMzMzLjc3IDEuMzMzIDIuNjk0IDAgMy40NjRMMjEgMzIuNjZjLTEuMzMzLjc3LTMtLjE5Mi0zLTEuNzMyVjE3LjA3MmMwLTEuNTQgMS42NjctMi41MDIgMy0xLjczMmwxMiA2LjkyOHoiIGZpbGw9IiNmZmYiLz48L3N2Zz4=")
        center center no-repeat;
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }
  & > svg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.2;
  }
`;

type ContentCardImageProps = CardAppearance & {
  item: Item;
  shouldShowImage?: boolean;
  image: Item["image"] | YouTubeImage;
  isYouTube?: boolean;
};

export const ContentCardImage: React.FC<ContentCardImageProps> = ({
  item,
  appearance,
  image,
  shouldShowImage = false,
  isYouTube = false
}) => {
  if (item?._category === "faq") {
    return appearance === "inline" ? (
      <FAQBackground variant={getFaqColorFromId(item._id)} size="small">
        <IconFaq width={40} height={40} />
      </FAQBackground>
    ) : (
      <FAQBackground variant={getFaqColorFromId(item._id)}>
        <FAQHeading>{item?.name}</FAQHeading>
      </FAQBackground>
    );
  }

  if (item?._category === "glossary") {
    return appearance === "inline" ? (
      <GlossaryBackground
        variant={getGlossaryVariantFromId(item._id)}
        size="small"
      >
        <IconGlossary width={40} height={40} />
      </GlossaryBackground>
    ) : (
      <GlossaryBackground variant={getGlossaryVariantFromId(item._id)}>
        <GlossaryHeading>{item?.name}</GlossaryHeading>
      </GlossaryBackground>
    );
  }

  if (image && shouldShowImage) {
    const src = getPrefferedImageUrl(image);
    const imageProps = {
      src,
      blurhash: getBlurHash(image),
      blurDataURL: getBlurDataURL(image)
    };
    const innerImage = (
      <Image
        {...imageProps}
        alt={item.name}
        layout="fill"
        objectFit="cover"
        objectPosition="top"
      />
    );

    return (
      <CardImage appearance={appearance} data-video={trueOrUndef(isYouTube)}>
        {innerImage}
        <span />
      </CardImage>
    );
  }

  if (!image && item?._category !== "glossary" && item?._category !== "faq") {
    return appearance === "inline" ? (
      <ItemPlaceholderImage
        shape={getItemLineShapeFromId(item._id)}
        size="small"
      >
        <ItemPlaceholderHeading>{item?.name}</ItemPlaceholderHeading>
      </ItemPlaceholderImage>
    ) : (
      <ItemPlaceholderImage shape={getItemLineShapeFromId(item._id)}>
        <ItemPlaceholderHeading>{item?.name}</ItemPlaceholderHeading>
      </ItemPlaceholderImage>
    );
  }

  if (appearance === "inline") {
    return (
      <PlaceholderImage>
        <PlaceholderLogo />
      </PlaceholderImage>
    );
  }

  return null;
};
