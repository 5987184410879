import React from "react";
import styled from "styled-components";
import NextImage, { ImageProps } from "next/image";
import { pxToRem } from "@input-output-hk/px-to-rem";
import useBlurhash from "../../hooks/useBlurhash";

const ImageContainer = styled.div`
  border-radius: ${pxToRem(15)};
  overflow: hidden;
  /* NextImage uses spans internally */
  line-height: 0;
`;

type Props = Pick<
  ImageProps,
  "src" | "alt" | "width" | "height" | "layout" | "objectFit" | "objectPosition"
> & {
  blurDataURL?: string;
  blurhash?: string;
  /**
   * Added to allow style overriding
   * from a parent component
   */
  className?: string;
};
const Image: React.FC<Props> = ({
  blurDataURL: maybeBlurDataURL,
  blurhash,
  className,
  ...props
}) => {
  const blurDataURL = useBlurhash(blurhash, { enable: !maybeBlurDataURL });
  return (
    <ImageContainer className={className}>
      <NextImage
        blurDataURL={blurDataURL}
        placeholder={blurDataURL ? "blur" : "empty"}
        {...props}
      />
    </ImageContainer>
  );
};

export default Image;
