import { useUser } from "@auth0/nextjs-auth0";
import {
  InteractionType,
  ReactActionType
} from "@input-output-hk/essential-cardano-models";
import Link from "next/link";
import React from "react";
import styled from "styled-components";
import { useLoginPrompt } from "../../../context/LoginPromptContext";
import { Item } from "../../../data/db/types";
import useItem from "../../../hooks/useItem";
import useVote from "../../../hooks/useVote";
import { pxToRem } from "@input-output-hk/px-to-rem";
import {
  forTabletPortraitDown,
  forTabletPortraitUp
} from "../../../tokens/media-queries";
import { trueOrUndef } from "../../../utils/dom-attribute-helpers";
import {
  createYouTubeImage,
  isSupported,
  YouTubeImage
} from "../../../utils/image";
import { Maybe } from "../../../utils/types";
import Heading from "../../heading/Heading";
import PinIcon from "../../icons/Pin";
import { CATEGORY_ICONS_SMALL } from "../../navigation/shared";
import DummyEmbed from "../../podcast/DummyEmbed";
import Text from "../../text/Text";
import VerifiedContentRibbon, {
  iVerifiedContentRibbon
} from "../../verified-content-ribbon/VerifiedContentRibbon";
import Vote from "../../vote/Vote";
import { CommentCountLink } from "../ItemIconAndCountLinks";
import { PosterInformation } from "../PosterInformation";
import { Tags } from "../Tags";
import { CardAppearance } from "./ContentCard.types";
import { ContentCardImage } from "./ContentCardImage";

type CardElementProps = CardAppearance & {
  verifiedAppearance?: iVerifiedContentRibbon["appearance"];
  showCategoryIcon?: boolean;
};

type Props = React.ComponentProps<typeof CardElement> &
  CardElementProps & {
    item: Item | null;
  };

export const CardElement = styled.article<CardElementProps>`
  --card-inner-padding: calc(var(--spacing-large) - var(--spacing-small));
  position: relative;
  padding: var(--spacing-small);
  background: var(--default-bg-color);
  border: var(--card-stroke);
  border-radius: var(--card-border-radius);
  box-shadow: var(--card-shadow);

  &[data-appearance="inline"] {
    display: flex;
    @media ${forTabletPortraitDown} {
      margin-bottom: ${pxToRem(10)};
    }
  }

  &[data-appearance="default"] {
    height: ${pxToRem(560)};
  }

  [data-image-link],
  [data-image-link]:visited,
  [data-image-link]:active {
    text-decoration: none;
  }
`;

export const TwoCards = styled.div`
  @media ${forTabletPortraitUp} {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    ${CardElement} {
      width: calc(50% - ${pxToRem(10)});
    }
  }
`;

const Pinned = styled(PinIcon)`
  color: var(--theme-primary);
  &[data-invert] {
    color: var(--default-text-color-invert);
  }
`;

const CardDecorations = styled.div`
  position: absolute;
  top: var(--spacing-large);
  left: ${pxToRem(-13)};
  right: var(--spacing-large);
  display: flex;
  align-items: center;
  & > * + * {
    margin-left: auto;
  }
  &[data-appearance="ribbon-top"] {
    flex-direction: column;
    left: calc(100% - ${pxToRem(60)});
    top: ${pxToRem(-2)};
    & > * + * {
      margin-left: unset;
      margin-top: ${pxToRem(16)};
    }
  }
`;

export const CardContent = styled.div`
  [data-appearance="large"] &,
  [data-appearance="default"] & {
    padding: var(--card-inner-padding);
    height: ${pxToRem(300)};
    overflow: hidden;
    word-wrap: break-word;
    & > * + * {
      margin-top: ${pxToRem(6)};
    }
  }
  [data-article-date] {
    margin-left: auto;
  }
`;

const CardTags = styled(Tags)`
  touch-action: pan-x;
  overflow: hidden;
  overflow-x: auto;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
  margin-bottom: ${pxToRem(10)};
`;

export const CardHeading = styled(Heading)<{ lineCount: number }>`
  font-size: var(--default-text-size);
  line-height: var(--medium-text-line-height);
  font-weight: 700;
  margin-bottom: ${pxToRem(16)};

  /* Line clamp :-/ */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${(props) => props.lineCount};
  overflow: hidden;

  & a {
    text-decoration: none;
    color: var(--default-text-color, inherit);
  }

  [data-appearance="inline"] & {
    margin: 0;
    -webkit-line-clamp: 2;
  }
`;

const CardText = styled(Text)<{ lineCount: number }>`
  /* Line clamp :-/ */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${(props) => props.lineCount};
  overflow: hidden;

  [data-appearance="large"] & {
    height: calc(var(--text-level2-lineHeight) * 5);
    -webkit-line-clamp: ${(props) => props.lineCount};
  }
`;

const ArticleVoteInline = styled(Vote)`
  padding: 0;
`;

const LoginHeading = styled(Heading)`
  margin: 0;
`;

const CardContentAndUpvotesContainer = styled.div`
  [data-appearance="inline"] & {
    padding-left: var(--spacing-small);
    margin-left: var(--spacing-default);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
`;

const IconsAndCounts = styled.div`
  --icon-and-count-gap: ${pxToRem(15)};
  position: absolute;
  left: ${pxToRem(8)};
  bottom: ${pxToRem(8)};
  display: flex;
  margin-top: auto;

  align-items: center;
  padding: var(--card-inner-padding);

  [data-appearance="inline"] & {
    padding: 0;
    position: relative;
    left: ${pxToRem(0)};
    bottom: ${pxToRem(0)};
  }

  & > * + * {
    margin-left: var(--icon-and-count-gap);
  }

  [data-vote] {
    --vote-button-gap: var(--icon-and-count-gap);
  }
`;

const CategoryIconContainer = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--nav-item-icon-color);
  width: ${pxToRem(26)};
  height: ${pxToRem(26)};
  border-radius: 50%;
  color: var(--default-text-color-invert);
  background-color: var(--default-text-color);
  margin-right: ${pxToRem(6)};
  margin-top: ${pxToRem(6)};
`;

const CategoryIconImageContainer = styled.div`
  position: relative;
`;

const ContentCard: React.FC<Props> = ({
  item: cardItem,
  children,
  appearance,
  showCategoryIcon = true,
  ...props
}) => {
  const { user } = useUser();
  const { showPrompt } = useLoginPrompt();

  const {
    item,
    comments,
    mutate: refetchItem
  } = useItem(cardItem?._slug as string, {
    fallbackData: {
      comments: [],
      item: cardItem
    },
    isPaused: () => true
  });

  const { vote, unvote, isLoading } = useVote(item?._id);

  if (!item) {
    if (process.env.NEXT_PUBLIC_VERCEL_ENV !== "production") {
      console.warn("ContentCard rendered without content!");
    }
    // Dont crash the app in production
    return null;
  }

  let image: Maybe<Item["image"] | YouTubeImage> = item.image;
  const { name, _category, _slug, video, _verifiedContent, commentCount } =
    item;
  const href = `/${_category}/${_slug}`;
  const isYouTube =
    _category === "video" && item.video?.url.startsWith("https://www.youtube");
  if (!image && isYouTube) {
    image = createYouTubeImage(video!.url);
  }
  const shouldShowImage =
    (image && image.url && isSupported(image.url)) || isYouTube;
  const isPodcast = _category === "podcast" && item.url;
  const plainContent = item._text ?? "";
  const plainContentLength = plainContent.length;
  const truncated =
    plainContentLength > 360
      ? plainContent.slice(0, 360) + "..."
      : plainContent;
  const showText = !children && appearance !== "inline";

  const Icon = CATEGORY_ICONS_SMALL[_category];

  const isRibbonTop =
    _category === "podcast" || _category === "other" ? true : false;

  const isGenericContentCard =
    item?._category !== "glossary" && item?._category !== "faq";

  // 38 is the average amount of characters that fit in a single line
  // on the content card heading in the new grid system
  const headingCharacterBreakpoint = 38;
  const headingLineCount = Math.ceil(
    item.name.length / headingCharacterBreakpoint
  );

  const maxCardTextLength = 7;

  const upvoteCount =
    item?.interactionStatistic.find(
      (v) => v.interactionType === InteractionType.UPVOTE
    )?.userInteractionCount || 0;
  const downvoteCount =
    item?.interactionStatistic.find(
      (v) => v.interactionType === InteractionType.DOWNVOTE
    )?.userInteractionCount || 0;

  const myVote = item?._userAction?.["@type"];
  const handleClickVote = user
    ? async (type: ReactActionType) => {
        const res = await (myVote === type ? unvote(type) : vote(type));
        if (res && "action" in res && "object" in res) {
          refetchItem({
            comments,
            item: {
              ...item!,
              interactionStatistic: res.object.interactionStatistic,
              _upvoteScore: res.object._upvoteScore,
              _userAction: res.action
            }
          });
        }
      }
    : () =>
        showPrompt(
          <>
            <LoginHeading level="3">Every vote counts</LoginHeading>
            <Text level="2">
              Login to cast your vote
              <br />
              and much more
            </Text>
          </>
        );

  let cardImageContent = (
    <CategoryIconImageContainer>
      <ContentCardImage
        item={item}
        appearance={appearance}
        shouldShowImage={shouldShowImage}
        image={image}
        isYouTube={isYouTube}
      />
      {showCategoryIcon && Icon && (
        <CategoryIconContainer>
          <Icon />
        </CategoryIconContainer>
      )}
    </CategoryIconImageContainer>
  );

  return (
    <CardElement
      {...props}
      data-content-card
      data-category={item._category}
      data-appearance={appearance}
      data-date-created={item.dateCreated}
      data-date-modified={item.dateModified}
    >
      {cardImageContent !== null ? (
        <Link href={href}>
          <a data-image-link>{cardImageContent}</a>
        </Link>
      ) : null}
      {isPodcast ? (
        <DummyEmbed title={item.name} href={item.url!} size="small" />
      ) : null}
      <CardContentAndUpvotesContainer>
        <CardContent>
          {appearance !== "inline" && (
            <>
              <PosterInformation item={item} />
              <CardTags tags={item._tags} enableCollapsing />
            </>
          )}
          {appearance === "inline" ||
          (isGenericContentCard && shouldShowImage) ? (
            <CardHeading
              level="2"
              lineCount={headingLineCount}
              data-id={item._id}
            >
              <Link href={href}>
                <a>{name}</a>
              </Link>
            </CardHeading>
          ) : null}

          {showText && (
            <CardText
              lineCount={
                isGenericContentCard && shouldShowImage
                  ? maxCardTextLength - headingLineCount > 0
                    ? maxCardTextLength - headingLineCount
                    : 0
                  : maxCardTextLength
              }
              level="2"
            >
              {truncated}
            </CardText>
          )}
        </CardContent>
        <CardDecorations
          data-appearance={
            _verifiedContent && isRibbonTop ? "ribbon-top" : "ribbon-left"
          }
        >
          {_verifiedContent && (
            <VerifiedContentRibbon
              appearance={isRibbonTop ? "ribbon-top" : "ribbon-left"}
            />
          )}
          {!!item._pinned && (
            <Pinned
              data-invert={trueOrUndef(shouldShowImage)}
              aria-label="This content is pinned"
              data-pinned={item._pinned}
            />
          )}
        </CardDecorations>
        <IconsAndCounts>
          <ArticleVoteInline
            myVote={myVote}
            onClick={handleClickVote}
            disabled={isLoading}
            upvoteCount={upvoteCount}
            downvoteCount={downvoteCount}
            totalCount={item._upvoteScore || 0}
          />
          <CommentCountLink count={commentCount} href={href} />
        </IconsAndCounts>
      </CardContentAndUpvotesContainer>
    </CardElement>
  );
};

ContentCard.defaultProps = {
  verified: null,
  appearance: "default"
};
export default ContentCard;
