import { Maybe } from "@input-output-hk/essential-cardano-models";
import { Item } from "../../../data/db/types";
import { YouTubeImage } from "../../../utils/image";

export function getBlurHash(image: Maybe<Item["image"] | YouTubeImage>) {
  if (image && "_blurhash" in image) {
    return image._blurhash?.hash;
  }
}
export function getBlurDataURL(image: Maybe<Item["image"] | YouTubeImage>) {
  if (image && "_blurDataURL" in image) {
    return image._blurDataURL;
  }
}
