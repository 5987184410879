import styled from "styled-components";
import { pxToRem } from "@input-output-hk/px-to-rem";
import { forTabletLandscapeUp } from "../../tokens/media-queries";

type BackgroundProps = {
  size?: "small" | "default" | "large";
  shape?: "spire" | "horizontal" | "wave";
};

export const ItemPlaceholderImage = styled.div.attrs<BackgroundProps>(
  (props) => ({
    "data-size": props.size || "default",
    "data-shape": props.shape || "horizontal"
  })
)<BackgroundProps>`
  --item-placeholder-color: #000000;
  --item-placeholder-background-color: #f2f2f2;
  --item-placeholder-background-size: ${pxToRem(395)};

  color: var(--item-placeholder-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--item-placeholder-background-color);
  background-size: var(--item-placeholder-background-size);
  background-repeat: no-repeat;

  padding: var(--spacing-large);
  padding-right: ${pxToRem(60)};
  position: relative;
  border-radius: ${pxToRem(15)};

  &[data-size="large"] {
    padding: ${pxToRem(40)};
  }
  &[data-size="default"] {
    min-height: ${pxToRem(215)};
  }
  &[data-size="small"] {
    --item-placeholder-background-size: ${pxToRem(138)};
    width: ${pxToRem(100)};
    height: ${pxToRem(100)};
  }
  @media ${forTabletLandscapeUp} {
    &[data-size="large"] {
      --item-placeholder-background-size: ${pxToRem(791)};

      min-height: ${pxToRem(415)};
      padding: ${pxToRem(80)};
      padding-right: 33%;
      font-size: ${pxToRem(26)};
      line-height: 1.538;
    }
    &[data-shape="spire"] {
      background-image: url("/cards/placeholder-bg-spire.png");
    }
    &[data-shape="wave"] {
      background-image: url("/cards/placeholder-bg-wave.png");
    }
    &[data-shape="horizontal"] {
      background-image: url("/cards/placeholder-bg-horizontal.png");
    }
  }
  & a[href] {
    color: inherit;
  }
`;

export const ItemPlaceholderTitle = styled.h1`
  color: var(--item-placeholder-text-color);
  font-weight: 400;
  margin: 0;
  @media ${forTabletLandscapeUp} {
    font-size: ${pxToRem(32)};
    line-height: 1.375;
  }
`;

export const ItemPlaceholderHeading = styled.h2`
  color: var(--item-placeholder-text-color);
  font-weight: 400;
  font-size: ${pxToRem(22)};
  line-height: 1.5454;
  margin: 0;
`;
