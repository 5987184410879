import { Svg, SvgProps } from "./Svg";

function PinIcon(props: SvgProps) {
  return (
    <Svg
      width={19}
      height={19}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-icon
      {...props}
    >
      <path
        d="M18.735 6.3L12.601.167a.567.567 0 00-.803 0l-.026.026A1.979 1.979 0 0011.19 1.6c0 .343.087.672.249.964L5.019 8.072a2.659 2.659 0 00-1.782-.68c-.716 0-1.389.28-1.894.786l-.039.038a.567.567 0 000 .802l3.675 3.675-2.58 2.58c-.07.073.235-.18-.879 1.208A76.17 76.17 0 01.24 18.06a.567.567 0 00.8.8c.01-.007.247-.213 1.578-1.28 1.39-1.114 1.135-.809 1.215-.886l2.573-2.573 3.478 3.478a.565.565 0 00.803 0l.038-.039a2.661 2.661 0 00.785-1.894 2.66 2.66 0 00-.68-1.782l5.51-6.419c.29.162.62.249.962.249.532 0 1.032-.207 1.408-.584l.026-.026a.568.568 0 000-.802z"
        fill="currentColor"
      />
    </Svg>
  );
}

export default PinIcon;
